<template>
	<nav class="nav text-body-3" :class="{ 'nav--collapsed': !isMenuVisible }">
		<router-link class="logo nav__logo" :class="{ 'logo--small': !isMenuVisible, 'nav__logo--open': !isMenuVisible }" to="/">
			Main Page
		</router-link>

		<router-link
			v-tooltip.bottom="isMenuVisible ? '' : 'New Campaign'"
			class="cta cta--plus cta--img nav__cta text-button"
			to="/adv/campaigns/new"
			@click="toggleMenuItem"
		>
			New Campaign
		</router-link>

		<ul class="nav__list menu">
			<li>
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Campaigns'"
					to="/adv/campaigns"
					class="menu__item menu__item--campaigns"
					@click="toggleMenuItem"
				>
					Campaigns
				</router-link>
			</li>

			<li>
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Reports'"
					to="/adv/reports"
					class="menu__item menu__item--statistics"
					@click="toggleMenuItem"
				>
					Reports
				</router-link>
			</li>

			<li>
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Tracking'"
					to="/adv/tracking"
					class="menu__item menu__item--tracking"
					@click="toggleMenuItem"
				>
					Tracking
				</router-link>
			</li>

			<li>
				<router-link
					v-if="account?.account_status !== 'pending'"
					v-tooltip.bottom="isMenuVisible ? '' : 'Finance'"
					to="/adv/finances"
					class="menu__item menu__item--finance"
					:class="{ 'router-link-active': $route.path === '/adv/finances-report' }"
					@click="toggleMenuItem"
				>
					Finance
				</router-link>
			</li>

			<li>
				<router-link
					v-if="isLibraryEnabled"
					v-tooltip.bottom="isMenuVisible ? '' : 'Creatives Library'"
					class="menu__item menu__item--creatives"
					to="/adv/creatives"
					@click="toggleMenuItem"
				>
					Creatives
				</router-link>
			</li>

			<li v-if="account.is_api_enabled">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'API'"
					to="/docs"
					class="menu__item menu__item--executor"
					@click="toggleMenuItem"
				>
					API
				</router-link>
			</li>

			<li>
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Rates'"
					to="/adv/rates"
					class="menu__item menu__item--rates-fare"
					@click="toggleMenuItem"
				>
					Rates
				</router-link>
			</li>

			<li>
				<a
					v-tooltip.bottom="isMenuVisible ? '' : 'Help Center'"
					href="https://help.rollerads.com/en/collections/6677641-for-advertisers"
					target="_blank"
					class="menu__item menu__item--help-center menu__item--without-focus"
					@click="toggleMenuItem"
				>
					Help Center
				</a>
			</li>

			<!-- li>
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Referrals'"
					to="/referrals"
					class="menu__item menu__item--referrals"
					@click="toggleMenuItem"
				>
					Referrals
				</router-link>
			</li -->
		</ul>
		<manager-badge
			v-if="manager.id && manager.contacts?.length"
			:manager-id="manager.id"
			:manager-name="manager.name"
			:contacts="manager.contacts"
		/>
	</nav>
</template>

<script>
	import { defineComponent } from 'vue';
	import EventBus from '@/services/local/EventBusService';
	import ManagerBadge from '@/components/ManagerBadge/ManagerBadge';
	import useMenuStore from '@/composables/useMenuStore';
	import User from '../../../services/local/UserService';

	export default defineComponent({
		name: 'LeftMenu',

		components: {
			ManagerBadge,
		},
		data() {
			return {
				account: User.currentUser.account || {},
				manager: User.currentUser.manager_info || {},
				isMenuVisible: true,

				isLibraryEnabled: User.isFeatureEnabled('creative_library'),
			};
		},

		created() {
			User.on('change', this.changeAdvMenu.bind(this));
		},

		beforeUnmount() {
			User.off('change', this.changeAdvMenu.bind(this));
			EventBus.off('menuToggled', this.menuToggledHandler.bind(this));
		},

		mounted() {
			EventBus.on('menuToggled', this.menuToggledHandler.bind(this));
			localStorage.isMenuVisible === 'false' ? (this.isMenuVisible = false) : (this.isMenuVisible = true);
			this.setMenuStore(this.isMenuVisible);
		},

		methods: {
			menuToggledHandler(val) {
				const { setMenu } = useMenuStore();
				this.isMenuVisible = this.isMenuVisible !== val;
				setMenu(this.isMenuVisible);
			},
			changeAdvMenu() {
				this.account = User.currentUser.account || {};
			},
			setMenuStore(value) {
				const { setMenu } = useMenuStore();
				setMenu(value);
			},
			toggleMenuItem() {
				if (window.innerWidth >= 1024) {
					return;
				} else if (window.innerWidth >= 768) {
					this.isMenuVisible = false;
					localStorage.isMenuVisible = false;
				} else {
					this.isMenuVisible = true;
					localStorage.isMenuVisible = true;
				}

				this.setMenuStore(this.isMenuVisible);
			},
		},
	});
</script>
